import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { NavigationItem } from "./NavigationItem";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import MuiDrawer from "@mui/material/Drawer";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import {
	Link,
	ListItemButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

import CookieConsent from "react-cookie-consent";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import DescriptionIcon from "@mui/icons-material/Description";
import NestedMenuList, {
	checkPath,
	hasSubMenuItems,
	getAuthenticatedMenuItems,
} from "./NestedMenuItem";
import { navigationList } from "./NavigationConfiguration";

const drawerWidth = 220;

type SideNavigationProp = {
	open: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledTypography = styled(Typography)(() => ({
	textTransform: "none",
	paddingTop: "5px",
	paddingBottom: "5px",
	letterSpacing: "1px",
	fontWeight: "400",
	component: "span",
}));

const StyledBottomPanel = styled(Typography)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	width: "100%",
	position: "absolute",
	bottom: "0",
	padding: "5px",
	backgroundColor: theme.palette.primary.light,
	color: theme.palette.primary.dark,
}));

const StyledLink = styled(Link)(() => ({
	marginLeft: "5px",
	marginRight: "5px",
	marginBottom: "5px",
}));

const StyledSelectedTypography = styled(StyledTypography)(({ theme }) => ({
	color: theme.palette.primary.main,
	letterSpacing: "1px",
	fontWeight: "500",
}));

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(5)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(7)} + 1px)`,
	},
});

const Drawer = styled(MuiDrawer, {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	shouldForwardProp: (prop: any) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

function SideNavigation(prop: SideNavigationProp): JSX.Element {
	const { pathname: pathName } = useLocation();
	const theme = useTheme();
	const { isAuthenticated } = useAuth0();
	const navigate = useNavigate();
	const finalNavigationList = getAuthenticatedMenuItems(
		isAuthenticated,
		navigationList
	);
	const gotoPage = (item: NavigationItem) => {
		navigate(item.path);
	};

	return (
		<Drawer open={prop.open} variant="permanent">
			<Toolbar />
			<List>
				{finalNavigationList.map((item: NavigationItem) =>
					hasSubMenuItems(isAuthenticated, item.subItems) ? (
						<NestedMenuList
							key={item.title + "nested-item" + item.path}
							item={item}
							isSideNavOpen={prop.open}
							isAuthenticated={isAuthenticated}
							currentPath={pathName}
							gotoPage={(subItem) => gotoPage(subItem)}
						></NestedMenuList>
					) : (
						<ListItemButton
							selected={checkPath(item, pathName)}
							key={"list-button" + item.path}
							onClick={() => gotoPage(item)}
						>
							<ListItemIcon
								key={"list-icon" + item.path}
								title={item.title}
							>
								<Box
									style={{
										color:
											item.path === pathName
												? theme.palette.primary.main
												: "",
									}}
								>
									<Tooltip title={item.path}>
										{item.icon}
									</Tooltip>
								</Box>
							</ListItemIcon>
							{item.path === pathName ? (
								<StyledSelectedTypography variant="button">
									{item.title}
								</StyledSelectedTypography>
							) : (
								<StyledTypography variant="button">
									{item.title}
								</StyledTypography>
							)}
						</ListItemButton>
					)
				)}
			</List>
			<CookieConsent
				location="bottom"
				buttonText="Accept"
				style={{
					background: theme.palette.primary.dark,
					color: theme.palette.primary.light,
					fontWeight: "bold",
				}}
				buttonStyle={{
					color: theme.palette.primary.dark,
					fontSize: "13px",
					fontWeight: "bold",
				}}
				expires={150}
			>
				This website uses cookies to enhance the user experience.
			</CookieConsent>
			{prop.open ? (
				<StyledBottomPanel>
					<Stack spacing={1}>
						<Link
							style={{
								fontWeight: "500",
								letterSpacing: "1px",
								margin: "5px",
							}}
							component="button"
							variant="body2"
							onClick={() => {
								navigate("aboutus");
							}}
						>
							Impressum
						</Link>
						<Box>
							<StyledLink
								href="https://www.linkedin.com/company/atalma"
								target="_blank"
							>
								<LinkedInIcon />
							</StyledLink>
							<StyledLink
								href="https://twitter.com/atalma_io"
								target="_blank"
							>
								<TwitterIcon />
							</StyledLink>
							<StyledLink
								href="https://atalma.io"
								target="_blank"
							>
								<DescriptionIcon />
							</StyledLink>
						</Box>
					</Stack>
				</StyledBottomPanel>
			) : (
				""
			)}
		</Drawer>
	);
}

export default SideNavigation;
