import React, { MouseEvent } from "react";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Profile from "../Common/Widgets/Profile";
import { useAuth0 } from "@auth0/auth0-react";
import fullLogo from "../assets/images/atalma_logo.png";
import LoginButton from "../Common/Widgets/Login/LoginButton";

type HeaderBarProp = {
	onMenuClick: (e: MouseEvent) => void;
};

export default function HeaderBar(props: HeaderBarProp): JSX.Element {
	const { isAuthenticated } = useAuth0();

	return (
		<AppBar
			position="fixed"
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			sx={{ zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
		>
			<Toolbar>
				<IconButton
					edge="start"
					color="inherit"
					aria-label="menu"
					onClick={(e) => props.onMenuClick(e)}
				>
					<MenuIcon />
				</IconButton>
				<Box display="flex" justifyContent="flex-end" marginRight={0.5}>
					<Typography variant="h5" component={"span"}>
						Vido | Node Explorer
					</Typography>
				</Box>
				<Box flexGrow={1} />
				<Box display="flex" justifyContent="flex-end" marginRight={0.5}>
					{isAuthenticated ? (
						<Profile></Profile>
					) : (
						<LoginButton isLink={true}></LoginButton>
					)}
				</Box>

				<Box
					component="img"
					sx={{
						height: 36,
						width: 115,
					}}
					alt="atalma"
					src={fullLogo}
				/>
			</Toolbar>
		</AppBar>
	);
}
