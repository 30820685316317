import { createTheme } from "@mui/material";

const theme = createTheme({
	palette: {
		primary: {
			main: "#326F6B",
			light: "#84C3BB",
			dark: "#233E3D",
		},
		secondary: {
			light: "#ff8166",
			main: "#FF6241",
			// dark: will be calculated from palette.secondary.main,
			contrastText: "#ffcc00",
		},
		// Used by `getContrastText()` to maximize the contrast between
		// the background and the text.
		contrastThreshold: 3,
		// Used by the functions below to shift a color's luminance by approximately
		// two indexes within its tonal palette.
		// E.g., shift from Red 500 to Red 300 or Red 700.
		tonalOffset: 0.2,
	},
	components: {
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: "#233E3D",
				},
			},
		},
	},
	typography: {
		fontFamily: "kanit, IBM Plex Sans",
		fontWeightBold: 500,
		h6: {
			fontWeight: 400,
			letterSpacing: 1,
		},
		h4: {
			fontWeight: 400,
		},
		subtitle1: {
			fontSize: 16,
			fontWeight: 500,
			letterSpacing: 1,
		},
		subtitle2: {
			fontFamily: "IBM Plex Sans",
			fontSize: 12,
			fontWeight: 600,
			letterSpacing: 0.5,
		},
		caption: {
			fontSize: 16,
			fontWeight: 600,
			letterSpacing: 1,
		},
		button: {
			letterSpacing: 1,
		},
		body1: {
			fontFamily: "IBM Plex Sans",
			fontSize: 16,
		},
	},
});
export default theme;
