import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";

const LogoutButton = (): React.ReactElement => {
	const { logout } = useAuth0();

	return (
		<Button
			variant="contained"
			style={{ margin: "5px" }}
			onClick={async () =>
				await logout({
					logoutParams: {
						returnTo: window.location.origin,
					},
				})
			}
		>
			Log Out
		</Button>
	);
};

export default LogoutButton;
