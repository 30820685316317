import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Typography } from "@mui/material";

type LoginButtonProp = {
	isLink: boolean;
};

const LoginButton = (prop: LoginButtonProp): React.ReactElement => {
	const { loginWithRedirect } = useAuth0();
	const buttonText = `Log In For Alerts`;

	return prop.isLink ? (
		<Box marginTop="3px" marginRight="15px">
			<Button
				variant={prop.isLink ? "outlined" : "contained"}
				color="inherit"
				size="small"
				onClick={async () =>
					await loginWithRedirect({
						async openUrl(url) {
							window.location.replace(url);
						},
					})
				}
			>
				<Box display="flex" alignItems="center" justifyContent="center">
					<Typography
						margin={"3px"}
						component="span"
						variant="subtitle1"
					>
						{buttonText}
					</Typography>
				</Box>
			</Button>
		</Box>
	) : (
		<Button
			variant="contained"
			style={{ margin: "5px" }}
			onClick={async () =>
				await loginWithRedirect({
					async openUrl(url) {
						window.location.replace(url);
					},
				})
			}
		>
			{buttonText}
		</Button>
	);
};

export default LoginButton;
