import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, ListItemIcon } from "@mui/material";
import { NavigationItem } from "./NavigationItem";

export function checkPath(item: NavigationItem, path: string): boolean {
	if (!path || !item || !item.path) {
		return false;
	}
	const rawPath = path.replace("/", "");
	const itemPathName = item.path.replace("/", "");
	return rawPath.includes(itemPathName);
}

export function hasSubMenuItems(
	isAuthenticated: boolean,
	subItems?: NavigationItem[]
): boolean {
	if (!subItems || subItems.length < 1) {
		return false;
	}
	const authItems = subItems.filter(
		(n) => isAuthenticated || !n.requireAuthentication
	);
	return authItems.length > 0;
}

export function getAuthenticatedMenuItems(
	isAuthenticated: boolean,
	items?: NavigationItem[]
): NavigationItem[] {
	return items.filter((n) => isAuthenticated || !n.requireAuthentication);
}

type NestedMenuListProp = {
	item: NavigationItem;
	currentPath: string;
	isAuthenticated: boolean;
	isSideNavOpen: boolean;
	gotoPage: (item: NavigationItem) => void;
};

export default function NestedMenuList(props: NestedMenuListProp): JSX.Element {
	const [open, setOpen] = React.useState(true);

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<Box>
			<ListItemButton onClick={handleClick}>
				<ListItemIcon>{props.item.icon}</ListItemIcon>
				<ListItemText primary={props.item.title} />
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{getAuthenticatedMenuItems(
						props.isAuthenticated,
						props.item.subItems
					).map(function (menuItem) {
						return (
							<ListItemButton
								sx={{ pl: props.isSideNavOpen ? 3 : 2 }}
								selected={checkPath(
									menuItem,
									props.currentPath
								)}
								key={
									"list-button" +
									props.item.title +
									menuItem.title
								}
								onClick={() => props.gotoPage(menuItem)}
							>
								<ListItemIcon
									key={
										"list-icon" +
										props.item.title +
										menuItem.title
									}
								>
									{menuItem.icon}
								</ListItemIcon>
								<ListItemText
									key={
										"list-text" +
										props.item.title +
										menuItem.title
									}
									primary={menuItem.title}
								/>
							</ListItemButton>
						);
					})}
				</List>
			</Collapse>
		</Box>
	);
}
