/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, CircularProgress } from "@mui/material";
import { Suspense, memo } from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Loader(props: any) {
	return (
		<Box
			display="flex"
			sx={{ height: "100vh", width: "100%" }}
			alignItems="center"
			justifyContent="center"
		>
			<CircularProgress color="primary" variant="indeterminate" />
		</Box>
	);
}
/**
 * It's taken react component as children and using suspense api to show fallback component
 * for lazy loading component using React.lazy https://reactjs.org/docs/code-splitting.html
 * @param props children i.e React Component
 */
const SuspenseContainer = memo(function SuspenseComponent(props: any) {
	return <Suspense fallback={<Loader />}>{props.children}</Suspense>;
});
export default SuspenseContainer;
