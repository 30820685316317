import { Icon, SvgIcon } from "@mui/material";

export const CustomCheckIcon = (): JSX.Element => (
	<Icon>
		<img
			src="https://vladiator-labs-ag.imgix.net/vido-checkmark.png"
			height={20}
			width={20}
		/>
	</Icon>
);

export function CeloIcon(): JSX.Element {
	return (
		<SvgIcon>
			<svg
				version="1.1"
				viewBox="0 0 2500 2500"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="1250" cy="1250" r="1250" fill="#4e632a" />
				<path
					fill="#fcf6f1"
					d="m1949.2 546.15h-1398.6v1407.7h1398.7v-491.4h-232.1c-80 179.3-260.1 304.1-466.2 304.1-284.1 0-514.2-233.6-514.2-517.5 0-284 230.1-515.6 514.2-515.6 210.1 0 390.2 128.9 470.2 312.1h228.1v-499.4z"
				/>
			</svg>
		</SvgIcon>
	);
}

export function CeloBaklavaIcon(): JSX.Element {
	return (
		<SvgIcon>
			<svg
				version="1.1"
				viewBox="0 0 2500 2500"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="1250" cy="1250" r="1250" fill="#625948" />
				<path
					fill="#fcf6f1"
					d="m1949.2 546.15h-1398.6v1407.7h1398.7v-491.4h-232.1c-80 179.3-260.1 304.1-466.2 304.1-284.1 0-514.2-233.6-514.2-517.5 0-284 230.1-515.6 514.2-515.6 210.1 0 390.2 128.9 470.2 312.1h228.1v-499.4z"
				/>
			</svg>
		</SvgIcon>
	);
}

export function NamadaIcon(): JSX.Element {
	return (
		<SvgIcon>
			<svg
				fill="none"
				viewBox="0 0 211 211"
				version="1.1"
				id="svg6"
				xmlns="http://www.w3.org/2000/svg"
			>
				<defs id="defs10" />
				<circle
					id="path446"
					fill="#ffff00"
					cx="103.5508"
					cy="105.98728"
					r="95.266739"
				/>
				<path
					fill="#000"
					d="M146.418 105.509h-39.451v39.451h39.451v-39.451ZM67.4976 66.0366c21.7731 0 39.4504 17.6775 39.4504 39.4504H67.4976V66.0366Zm-.0001 39.4724L44.7212 144.96h45.5526l-22.7763-39.451Zm79.0665-.034c10.887 0 19.712-8.8261 19.712-19.7131 0-10.8869-8.825-19.7126-19.712-19.7126s-19.713 8.8257-19.713 19.7126c0 10.887 8.826 19.7131 19.713 19.7131Z"
					id="path2"
				/>
				<path
					fill="#000"
					d="M105.5 210.997c-28.1799 0-54.674-10.972-74.5993-30.901C10.9723 160.171 0 133.677 0 105.497c0-28.1801 10.9723-54.671 30.9007-74.5963C50.826 10.9723 77.3201 0 105.5 0c28.18 0 54.674 10.9723 74.599 30.9007C200.025 50.826 211 77.3201 211 105.5c0 28.18-10.972 54.674-30.901 74.599C160.174 200.025 133.68 211 105.5 211v-.003Zm0-197.9262c-24.6876 0-47.8988 9.6134-65.3541 27.0719S13.074 80.8093 13.074 105.497c0 24.687 9.6134 47.899 27.0719 65.354 17.4584 17.458 40.6665 27.072 65.3541 27.072 24.688 0 47.899-9.614 65.354-27.072s27.072-40.667 27.072-65.354c0-24.6877-9.613-47.899-27.072-65.3543-17.458-17.4553-40.666-27.0719-65.354-27.0719Z"
					id="path4"
				/>
			</svg>
		</SvgIcon>
	);
}

export const LOGO_IMAGE_SOURCE =
	"https://vladiator-labs-ag.imgix.net/vladiator-labs-logo.png";
export const CHECKMARK_IMAGE_SOURCE =
	"https://vladiator-labs-ag.imgix.net/vido-checkmark.png";
export const SORT_UP_IMAGE_SOURCE =
	"https://vladiator-labs-ag.imgix.net/vido-sort-up.svg";
export const SORT_DOWN_IMAGE_SOURCE =
	"https://vladiator-labs-ag.imgix.net/vido-sort-down.svg";
export const FAVORITE_BLACK_IMAGE_SOURCE =
	"https://vladiator-labs-ag.imgix.net/vido-favorite_black.svg";
export const FAVORITE_WHITE_IMAGE_SOURCE =
	"https://vladiator-labs-ag.imgix.net/vido-favorite_white.svg";
export const LOADING_IMAGE_SOURCE =
	"https://vladiator-labs-ag.imgix.net/vido-loading.svg";
