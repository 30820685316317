import React from "react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/kanit/300.css";
import "@fontsource/kanit/400.css";
import "@fontsource/kanit/500.css";
import "@fontsource/kanit/700.css";
import "@fontsource/ibm-plex-sans";
import "@fontsource/ibm-plex-sans/400.css";
import { createRoot } from "react-dom/client";
import Auth0ProviderWithNavigate from "./Auth0ProviderWithNavigate";
import { BrowserRouter } from "react-router-dom";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Auth0ProviderWithNavigate />
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
