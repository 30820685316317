/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, lazy, MouseEvent, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import theme from "./theme";
import HeaderBar from "./Shell/HeaderBar";
import {
	Box,
	CircularProgress,
	CssBaseline,
	ThemeProvider,
} from "@mui/material";
import SideNavigation from "./Shell/SideNavigation";
import SuspenseContainer from "./Common/Widgets/SuspenseContainer";
import {
	AttestationNetworks,
	CurrentNetwork,
	MetricsNetworks,
	NamadaSignedBlockNetworks,
	SignedBlockNetworks,
	SubscriptionNetworks,
} from "./Common/Models/CustomTypes";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AboutUs from "./AboutUs";

// https://reactjs.org/docs/code-splitting.html
const AsyncNotFound = lazy(() => import("./NotFound"));
const AsyncSignedBlocks = lazy(() => import("./Celo/SignedBlocks"));
const AsyncAttestationMap = lazy(() => import("./Celo/AttestationMap"));
const AsyncMetricsMap = lazy(() => import("./Celo/Metrics"));
const AsyncNamadaSignedBlocks = lazy(() => import("./Namada/SignedBlocks"));
const AsyncSubscriptions = withAuthenticationRequired(
	lazy(() => import("./Celo/Subscriptions"))
);
const signedBlocks: string[] = SignedBlockNetworks;
const attestationMaps: string[] = AttestationNetworks;
const metricsMaps: string[] = MetricsNetworks;
const subscriptionsMaps: string[] = SubscriptionNetworks;
const namadaSignedBlocks: string[] = NamadaSignedBlockNetworks;

type AppProp = { appName: string };
type AppState = { open: boolean };

class App extends Component<AppProp, AppState> {
	constructor(props: AppProp) {
		super(props);
		this.state = { open: true };
		localStorage.getItem(CurrentNetwork);
	}

	onMenuClick = (event: MouseEvent): void => {
		event.stopPropagation();
		event.preventDefault();
		if (this.state.open) {
			this.handleDrawerClose();
		} else {
			this.handleDrawerOpen();
		}
	};

	handleDrawerOpen = (): void => {
		this.setState({
			open: true,
		});
	};

	handleDrawerClose = (): void => {
		this.setState({
			open: false,
		});
	};

	render(): JSX.Element {
		return (
			<ThemeProvider theme={theme}>
				<Box sx={{ display: "flex" }}>
					<CssBaseline />
					<Suspense
						fallback={
							<Box
								display="flex"
								sx={{ height: "100vh", width: "100%" }}
								alignItems="center"
								justifyContent="center"
							>
								<CircularProgress color="primary" />
							</Box>
						}
					>
						<HeaderBar onMenuClick={this.onMenuClick} />
						<SideNavigation open={this.state.open} />
						<Box
							component="main"
							sx={{ flexGrow: 1 }}
							paddingTop="60px"
						>
							<Routes>
								<Route
									path="/"
									element={
										<SuspenseContainer>
											<AsyncSignedBlocks
												network={
													localStorage.getItem(
														CurrentNetwork
													) ?? "Mainnet"
												}
												lookback={100}
											/>
										</SuspenseContainer>
									}
								/>
								{/* <Redirect from="/" to="/block-map" /> */}

								{attestationMaps.map((am, i) => (
									<Route
										key={i}
										path={
											"/" +
											(am.toLowerCase() === "mainnet"
												? ""
												: am.toLowerCase() + "-") +
											"attestations"
										}
										element={
											<SuspenseContainer>
												<AsyncAttestationMap
													key={i}
													network={am}
													lookback={50}
												/>
											</SuspenseContainer>
										}
									/>
								))}

								<Route
									key={`celo-mainnet-route-${signedBlocks[0]}`}
									path={"/celo/block-map"}
									element={
										<SuspenseContainer>
											<AsyncSignedBlocks
												key={`celo-mainnet-page-${signedBlocks[0]}`}
												network={signedBlocks[0]}
												lookback={100}
											/>
										</SuspenseContainer>
									}
								/>

								<Route
									key={`celo-baklava-route-${signedBlocks[1]}`}
									path={"/celo-baklava/block-map"}
									element={
										<SuspenseContainer>
											<AsyncSignedBlocks
												key={`celo-baklava-page-${signedBlocks[1]}`}
												network={signedBlocks[1]}
												lookback={100}
											/>
										</SuspenseContainer>
									}
								/>

								<Route
									key={`celo-mainnet-attestations-route-${attestationMaps[0]}`}
									path={"/celo/attestations"}
									element={
										<SuspenseContainer>
											<AsyncAttestationMap
												key={`celo-mainnet-attestations-page-${attestationMaps[0]}`}
												network={attestationMaps[0]}
												lookback={50}
											/>
										</SuspenseContainer>
									}
								/>

								<Route
									key={`celo-baklava-attestations-route-${attestationMaps[1]}`}
									path={"/celo-baklava/attestations"}
									element={
										<SuspenseContainer>
											<AsyncAttestationMap
												key={`celo-baklava-attestations-page-${attestationMaps[1]}`}
												network={attestationMaps[1]}
												lookback={50}
											/>
										</SuspenseContainer>
									}
								/>

								<Route
									key={`celo-mainnet-metrics-route-${metricsMaps[0]}`}
									path={"/celo/metrics"}
									element={
										<SuspenseContainer>
											<AsyncMetricsMap
												key={`celo-mainnet-metrics-page-${metricsMaps[0]}`}
												network={metricsMaps[0]}
											/>
										</SuspenseContainer>
									}
								/>

								<Route
									key={`celo-baklava-metrics-route-${metricsMaps[1]}`}
									path={"/celo-baklava/metrics"}
									element={
										<SuspenseContainer>
											<AsyncMetricsMap
												key={`celo-baklava-metrics-page-${metricsMaps[1]}`}
												network={metricsMaps[1]}
											/>
										</SuspenseContainer>
									}
								/>

								<Route
									key={`celo-mainnet-subscriptions-route-${subscriptionsMaps[0]}`}
									path={"/celo/subscriptions"}
									element={
										<SuspenseContainer>
											<AsyncSubscriptions
												key={`celo-mainnet-subscriptions-page-${subscriptionsMaps[0]}`}
												network={subscriptionsMaps[0]}
											/>
										</SuspenseContainer>
									}
								/>

								<Route
									key={`celo-baklava-subscriptions-route-${subscriptionsMaps[1]}`}
									path={"/celo-baklava/subscriptions"}
									element={
										<SuspenseContainer>
											<AsyncSubscriptions
												key={`celo-baklava-subscriptions-page-${subscriptionsMaps[1]}`}
												network={subscriptionsMaps[1]}
											/>
										</SuspenseContainer>
									}
								/>

								<Route
									key={`namada-block-map-mainnet}`}
									path={"/namada/block-map"}
									element={
										<SuspenseContainer>
											<AsyncNamadaSignedBlocks
												network={namadaSignedBlocks[0]}
												lookback={100}
											/>
										</SuspenseContainer>
									}
								/>

								<Route path="/aboutus" element={<AboutUs />} />
								<Route path="*" element={<AsyncNotFound />} />
							</Routes>
						</Box>
					</Suspense>
				</Box>
			</ThemeProvider>
		);
	}
}

export default App;
