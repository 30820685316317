import * as React from "react";
import {
	Avatar,
	Box,
	Card,
	CardContent,
	Link,
	Stack,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import logo from "../assets/images/green_logo.png";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledHeader = styled(Typography)(() => ({
	marginLeft: "10px",
	marginTop: "15px",
	padding: 0,
	component: "span",
	color: "primary",
}));

const StyledTypography = styled(Typography)(() => ({
	marginLeft: "10px",
	component: "span",
	variant: "body1",
}));

class AboutUs extends React.Component {
	render = (): JSX.Element => (
		<Box m={1} pt={1}>
			<Card>
				<React.Fragment>
					<CardContent>
						<Box
							sx={{
								paddingLeft: "15px",
								display: "flex",
								flexDirection: "column",
								height: "calc(100vh - 210px)",
								overflow: "auto",
								overflowY: "auto",
							}}
						>
							<Stack>
								<Box style={{ padding: "5px" }}>
									<Stack direction="row" spacing={0}>
										<Box
											display="flex"
											alignItems="center"
											justifyContent="center"
										>
											<Avatar
												alt="Atalma AG"
												src={logo}
											/>
										</Box>
										<Box
											display="flex"
											alignItems="center"
											justifyContent="center"
										>
											<StyledTypography
												margin={"10px"}
												variant="subtitle1"
												color="primary"
											>
												Atalma AG
											</StyledTypography>
										</Box>
									</Stack>
								</Box>

								<StyledHeader variant="subtitle1">
									Address
								</StyledHeader>

								<StyledTypography>
									Atalma AG
									<br />
									Rote Trote 9 <br />
									63340 Baar <br />
									Switzerland <br />
								</StyledTypography>

								<StyledHeader variant="subtitle1">
									Email & Website
								</StyledHeader>

								<Link href={`mailto:admin@atalma.io`}>
									<StyledTypography>
										admin@atalma.io
									</StyledTypography>
								</Link>
								<Link href={`https://atalma.io`}>
									<StyledTypography>
										https://atalma.io
									</StyledTypography>
								</Link>

								<StyledHeader variant="subtitle1">
									Handelsregister-Nummer
								</StyledHeader>

								<Link
									href={`https://zg.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=CHE-450.603.042`}
								>
									<StyledTypography>
										CHE-450.603.042
									</StyledTypography>
								</Link>

								<StyledHeader variant="subtitle1">
									VAT Identification
								</StyledHeader>

								<Link
									href={`https://www.uid.admin.ch/Detail.aspx?uid_id=CHE-450.603.042`}
								>
									<StyledTypography>
										CHE-450.603.042 MWST
									</StyledTypography>
								</Link>

								<StyledHeader variant="subtitle1">
									Vertretungsberechtigte Personen
								</StyledHeader>
								<StyledTypography>
									Aaron Boyd, Geschäftsführer
								</StyledTypography>

								<StyledHeader variant="subtitle1">
									Haftungsausschluss
								</StyledHeader>
								<StyledTypography>
									Der Autor übernimmt keinerlei Gewähr
									hinsichtlich der inhaltlichen Richtigkeit,
									Genauigkeit, Aktualität, Zuverlässigkeit und
									Vollständigkeit der Informationen.
									Haftungsansprüche gegen den Autor wegen
									Schäden materieller oder immaterieller Art,
									welche aus dem Zugriff oder der Nutzung bzw.
									Nichtnutzung der veröffentlichten
									Informationen, durch Missbrauch der
									Verbindung oder durch technische Störungen
									entstanden sind, werden ausgeschlossen. Alle
									Angebote sind unverbindlich. Der Autor
									behält es sich ausdrücklich vor, Teile der
									Seiten oder das gesamte Angebot ohne
									gesonderte Ankündigung zu verändern, zu
									ergänzen, zu löschen oder die
									Veröffentlichung zeitweise oder endgültig
									einzustellen.
								</StyledTypography>

								<StyledHeader variant="subtitle1">
									Haftung für Links
								</StyledHeader>
								<StyledTypography>
									Verweise und Links auf Webseiten Dritter
									liegen ausserhalb unseres
									Verantwortungsbereichs Es wird jegliche
									Verantwortung für solche Webseiten
									abgelehnt. Der Zugriff und die Nutzung
									solcher Webseiten erfolgen auf eigene Gefahr
									des Nutzers oder der Nutzerin.
								</StyledTypography>

								<StyledHeader variant="subtitle1">
									Urheberrechte
								</StyledHeader>
								<StyledTypography>
									Die Urheber- und alle anderen Rechte an
									Inhalten, Bildern, Fotos oder anderen
									Dateien auf der Website gehören
									ausschliesslich der oben genannten
									Firma/Person oder den speziell genannten
									Rechtsinhabern. Für die Reproduktion
									jeglicher Elemente ist die schriftliche
									Zustimmung der Urheberrechtsträger im Voraus
									einzuholen
								</StyledTypography>
							</Stack>
						</Box>
					</CardContent>
				</React.Fragment>
			</Card>
		</Box>
	);
}
export default AboutUs;
