import React from "react";
import {
	Avatar,
	Box,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Popover,
} from "@mui/material";
import "./filter.css";
import LoginButton from "./Login/LoginButton";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "./Login/LogoutButton";

export default function Profile(): JSX.Element {
	const { user, isAuthenticated } = useAuth0();

	const [state, setState] = React.useState({
		anchorEl: undefined,
	});
	const open = Boolean(state.anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setState({ ...state, anchorEl: event.target });
	};

	const handleClose = () => {
		setState({
			...state,
			anchorEl: null,
		});
	};

	return (
		<div>
			<IconButton
				edge="start"
				color="inherit"
				onClick={handleClick}
				aria-label="menu"
			>
				<Avatar
					sx={{ width: 36, height: 36 }}
					alt={user.name}
					src={user.picture}
				/>
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={state.anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<Box width={340} height={170}>
					{isAuthenticated ? (
						<Box margin={2}>
							<List
								sx={{
									width: "100%",
									maxWidth: 360,
									bgcolor: "background.paper",
								}}
							>
								<ListItem disablePadding={true}>
									<ListItemAvatar>
										<Avatar
											alt={user.name}
											src={user.picture}
										/>
									</ListItemAvatar>
									<ListItemText
										primary={user.name}
										secondary={user.email}
									/>
								</ListItem>
							</List>
							<Box
								display="flex"
								justifyContent="flex-end"
								marginTop={1}
								marginRight={"5px"}
							>
								<Link href="/subscriptions" color="inherit">
									Manage Subscriptions
								</Link>
							</Box>
							<Box
								display="flex"
								justifyContent="flex-end"
								marginTop={1}
							>
								<LogoutButton></LogoutButton>
							</Box>
						</Box>
					) : (
						<Box marginBottom={2} marginRight={2} marginLeft={2}>
							<List
								sx={{
									width: "100%",
									maxWidth: 360,
									bgcolor: "background.paper",
								}}
							>
								<ListItem disablePadding={true}>
									<ListItemText
										primary="For subscriptions, notifications and more"
										secondary="Vido account is required."
									/>
								</ListItem>
							</List>
							<Box
								display="flex"
								justifyContent="flex-end"
								marginTop={1}
							>
								<LoginButton isLink={false}></LoginButton>
							</Box>
						</Box>
					)}
				</Box>
			</Popover>
		</div>
	);
}
